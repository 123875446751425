import React, { useState } from "react";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loading.css";
import image from "./images/laoding.gif";

function App() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleCountryChange = (e) => setCountry(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await fetch(
        "https://azinhealthai.onrender.com/api/person",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fullName, email, phoneNumber, country }),
        }
      );

      if (response.ok) {
        // Reset form fields after successful submission
        setFullName("");
        setEmail("");
        setPhoneNumber("");
        setCountry("");
        setError("");
        toast("Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }); // Clear any previous errors
        setLoading(false);
      } else {
        const data = await response.json();
        setError("Failed to submit form");
        toast("Oops!! Register again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }); // Clear any previous errors
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast("Opps! Register again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }); // Clear any previous errors
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {loading && (
        <div className="overlay">
          <img
            src={image} // Replace with the path to your loading GIF
            alt="Loading"
          />
          <h4>Loading...</h4>
        </div>
      )}{" "}
      <div className="d-flex mx-auto mt-5">
        <div className="card-body">
          <h2 className="card-title fs-4 mb-4 text-center text-white fw-bolder">
            Discover your new Digital Health Assistant
            <br /> Join our mission to make healthcare <br /> more accessible
            and affordable.
          </h2>
          <h2 className="card-title fs-4 text-center text-white">
            SIGN UP NOW!!!
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="col-md-5 mx-auto">
              <label className="text-white" htmlFor="fullName">
                Full Name
              </label>
              <input
                className="form-control mb-4"
                type="text"
                name="fullName"
                id="fullName"
                placeholder="Full Name"
                value={fullName}
                onChange={handleFullNameChange}
                required
              />
            </div>
            <div className="col-md-5 mx-auto">
              <label className="text-white" htmlFor="email">
                Email Address
              </label>
              <input
                className="form-control mb-4"
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="col-md-5 mx-auto">
              <label className="text-white" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                className="form-control mb-4"
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
            </div>
            <div className="col-md-5 mx-auto">
              <label className="text-white" htmlFor="country">
                Country
              </label>
              <input
                className="form-control mb-4"
                type="text"
                name="country"
                id="country"
                placeholder="Country"
                value={country}
                onChange={handleCountryChange}
                required
              />
            </div>
            <div className="d-flex justify-content-center col-md-5 mx-auto">
              <button className="btn btn-outline-dark" disabled={loading}>
                Sign Up ↦{" "}
              </button>
            </div>
          </form>
          {loading && <p>Loading...</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
}

export default App;
